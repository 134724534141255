import { HTTP_YAF } from '@/service/axios'
import store from "@/store/index";
import Vue from 'vue'
let _this = Vue.prototype
export async function getPermissionList () {
  try {
    // 获取权限 101
    // if (localStorage.getItem("UCSTATE") == 1 || sessionStorage.getItem('auth') == null || sessionStorage.getItem('auth') == '') {
      const auth = await HTTP_YAF.post('/user/user/getroleauthlist', {
        platform_id: 101
      })

      sessionStorage.setItem('auth', JSON.stringify(auth.data))
      localStorage.setItem("UCSTATE", 2)
      return auth.data || []
    // } else {
    //   return JSON.parse(sessionStorage.getItem('auth'))
    // }
  } catch (error) {
    throw (error)
  }
}

// 转换权限数据格式
export function transform (list) {
  let obj = {}
  let nav = list.filter(v => v.power_type < 3)
  let btn = list.filter(v => v.power_type === 3)
  nav.forEach(item => {
    let key = item.router_node
    let child = btn.filter(v => +v.pid === +item.power_id && v.has_role)
    if (!obj[key]) {
      obj[key] = []
      if(item.router_node === 'ASSETS_OCEAN_PRODUCTMANAGE') {
      }
      if (item.has_role) {
        obj[key].push('LOOK')
      }
      if (child.length > 0) {
        child.forEach(v => obj[key].push(v.router_node))
      }
    }
  })
  return obj
}
// 根据权限跳转路由
export const getPermission = async (to, next, num) => {
  try {

    let res = await getPermissionList()
    if (!res) {
      await getPermission(to, next, --num)
      return
    }
    let [{ role_power_menu = [] } = {}] = res
    let auth = transform(role_power_menu)
    store.commit('setPower', auth)

    localStorage.setItem('setPower', JSON.stringify(auth))
    if (to.path.includes(process.env.VUE_APP_ADSAAS_ADMIN+'/oceanapp/index')) {
      // window.location.href = '/index'
      window.location.href = '/login';

    } else {
      next()
    }
  } catch (error) {
    if (num === 0) {
      Vue.prototype.$message.error(error.message || '网络异常')
      next('/notPermission')
    } else {
      await getPermission(to, next, --num)
    }
  }
}
